import React, { useCallback, useEffect, useState } from "react";
import { PlotModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import CognitoUser from "../../../components/CognitoUser";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface PlotDetailsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
}

const PlotUsersTab: React.FC<PlotDetailsTabProps> = ({ plot, getPlot }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<{ id: number }[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  let { siteId } = useParams();
  const [addedUsers, setAddedUsers] = useState(plot.users);

  const getUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const { data } = await axios.get<{ id: number }[]>(`/admin/users/site/${siteId}`);
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios.put(`/admin/plots/${plot.id}`, {
        ...plot,
        users: JSON.stringify(addedUsers),
      });
      toast("Saved plot users", { type: "success" });
      getPlot();
    } catch (error) {
      toast("Failed to save plot users", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <form className="max-w-3xl" onSubmit={onSubmit}>
      <div className="p-5 bg-orange-200 bg-opacity-50 italic text-gray-600 border-2 border-opacity-50 border-orange-500 rounded-md">
        You can manage individual users access to plots here. This list excludes admin
        users as users with the admin role have access to all plots.
      </div>

      <div className="max-w-4xl rounded-md overflow-hidden">
        {users.length > 0 ? (
          <>
            {users.map((user, index) => (
              <CognitoUser
                type="admin"
                userId={user.id}
                render={(userD) => (
                  <label
                    htmlFor="manage-trades"
                    className={`bg-gray-200 p-5 my-5 rounded-md flex items-center justify-between`}
                  >
                    <div className="w-5/6">
                      {userD.name}({userD.email}) - <span className="text-gray-400 font-medium">{userD.owner ? 'ADMIN' : 'USER'}</span>
                    </div>
                    <div className="w-1/6">
                      <input
                        id="manage-trades"
                        type="checkbox"
                        placeholder="Admin"
                        checked={addedUsers.includes(user.id)}
                        onChange={() => {
                          if (addedUsers.includes(user.id)) {
                            setAddedUsers((prevUsers) =>
                              prevUsers.filter((prU) => prU !== user.id)
                            );
                          } else {
                            setAddedUsers([...addedUsers, user.id]);
                          }
                        }}
                        className="w-4 h-4 cursor-pointer text-orange-400 bg-white rounded border-white focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-white dark:border-white mr-4"
                      />
                    </div>
                    {/* {userD.owner === true ? (
                      <p className="text-gray-400 font-medium">ADMIN</p>
                    ) : (
                      <input
                        id="manage-trades"
                        type="checkbox"
                        checked={addedUsers.includes(user.id)}
                        onChange={() => {
                          if (addedUsers.includes(user.id)) {
                            setAddedUsers((prevUsers) =>
                              prevUsers.filter((prU) => prU !== user.id)
                            );
                          } else {
                            setAddedUsers([...addedUsers, user.id]);
                          }
                        }}
                        className="w-4 h-4 text-orange-400 bg-white rounded border-white focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-white dark:border-white mr-4"
                      />
                    )} */}
                  </label>
                )}
              />
            ))}
          </>
        ) : (
          <>
            {loadingUsers ? (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                Loading Users List
              </div>
            ) : (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                You do not currently have any active users, invite a user below.
              </div>
            )}
          </>
        )}
      </div>

      <button
        type="submit"
        disabled={loading}
        className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
      >
        {loading ? "Loading..." : "Save Plot users"}
      </button>
      {/* <div className="p-4 rounded-md bg-white">
        <h3 className="text-xl font-medium">Export</h3>
        <>
          <p className="text-gray-500 text-sm">
            <strong>{plot.name}</strong> is a part of the enterprise program
          </p>
          <div
            role="button"
            onClick={() => {
              //   if (!loading) {
              //     renewSuccessClick();
              //   }
            }}
            className="bg-green-600 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
          >
            {loading ? "Loading...." : "Export"}
          </div>
        </>
      </div> */}
    </form>
  );
};

export default PlotUsersTab;
