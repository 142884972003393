import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "../plugins/axios";
import { FormProps } from "../types/app";

type Inputs = {
  name: string;
  location: string;
  stage_inspection: string;
};

const CreateSiteForm: React.FC<FormProps> = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({});

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      const { data } = await axios.post<any>("/sites", formData);
      navigate(`/sites/${data.id}`);
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Create new Site/Project</h2>
      <div className="my-6">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Name
        </label>
        <input
          {...register("name", { required: true, minLength: 3 })}
          placeholder="Enter Site/Project name (min 3 characters required)"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.name && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Site name is required and must be more than 3 characters
          </span>
        )}
      </div>
      <div className="my-6">
        <label htmlFor="location" className="text-sm font-medium mb-2">
          Location
        </label>
        <input
          id="location"
          {...register("location", { required: false })}
          placeholder="Enter site location"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
      </div>
      <button
        type="submit"
        className="focus:outline-none text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-orange-900"
      >
        Create site
      </button>
    </form>
  );
};

export default CreateSiteForm;
