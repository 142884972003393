import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { SiteModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import { toast } from "react-toastify";

interface SiteDetailsTabProps {
  site: SiteModel;
  getSite: () => Promise<void>;
}

type Inputs = {
  name: string;
  location: string;
  stage_inspection: string;
};

const SiteDetailsTab: React.FC<SiteDetailsTabProps> = ({ site, getSite }) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: site,
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (loading) return;

    setLoading(true);
    try {
      await axios.put(`/admin/sites/${site.id}`, {
        ...formData,
        trades: JSON.stringify(site.trades),
        users: JSON.stringify(site.users),
      });
      toast("Site details saved!", { type: "success" });
      getSite();
    } catch (error) {
      toast("Failed to update site details", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Site Name:</label>
          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block w-full"
            {...register("name", { required: true, minLength: 3 })}
            type="text"
            required
          ></input>
        </div>

        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Location:</label>
          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block w-full"
            {...register("location", { required: false, minLength: 3 })}
            type="text"
            required
          ></input>
        </div>

        <div>
          <button
            type="submit"
            disabled={loading}
            className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
          >
            {loading ? "Loading..." : "Save Site details"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SiteDetailsTab;
