import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { RoomModel } from "../../types/api";
import axios from "../../plugins/axios";
import { PulseLoader } from "react-spinners";
import FailedToLoadDetails from "../../components/FailedToLoadDetails";
import DragAndDropSortList from "../../components/DragAndDropSortList";
import Modal from "../../components/Modal";
import CreateSnagLocationForm from "../../forms/CreateSnagLocationForm";

const LocationLi = ({
  id,
  title,
  sort,
}: {
  id: number;
  title: string;
  sort: number;
}) => {
  return <div>{title}</div>;
};

const RoomScreen: React.FC = () => {
  const navigate = useNavigate();
  let { roomId } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [room, setRoom] = useState<RoomModel | null>();
  const [showAdd, setShowAdd] = useState(false);

  const getRoomById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<RoomModel>(
        `/superadmin/content/rooms/${roomId}`
      );
      setRoom(data);
      setError(false);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [roomId]);

  useEffect(() => {
    getRoomById();
  }, [getRoomById]);

  const updateRoomsSorting = useCallback(
    async (res: { id: number; sort: number }[]) => {
      try {
        await axios.put(`/superadmin/content/rooms/${roomId}/sorting`, {
          locations: res,
        });
      } catch (error) {}
    },
    [roomId]
  );

  if (error) return <FailedToLoadDetails />;
  if (!room || loading)
    return (
      <div className="p-6 text-gray-500 font-medium text-lg">
        <PulseLoader color="#fb923c" size={10} />
      </div>
    );
  return (
    <div className="p-6">
      <div className="flex items-center justify-between">
        <div>
          <div
            role="button"
            className="flex items-center text-xs font-medium text-gray-500 hover:text-black"
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoArrowBackOutline className="mr-2" />
            Back to Content
          </div>
          <h1 className="text-orange-400 text-xl font-medium my-6">
            {room.title}
          </h1>
        </div>
        <div>
          <button
            onClick={() => setShowAdd(true)}
            className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
          >
            Create snag type
          </button>
        </div>
      </div>

      <DragAndDropSortList
        onChange={(v) => {
          const payload = v.map((v, sort) => ({
            id: v as number,
            sort,
          }));
          updateRoomsSorting(payload);
        }}
        items={
          room.snaglocations.map((location) => ({
            id: location.id,
            component: <LocationLi {...location} />,
          })) ?? []
        }
      />
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <CreateSnagLocationForm
          onCompleted={() => {
            getRoomById();
            setShowAdd(false);
          }}
          room={room}
        />
      </Modal>
    </div>
  );
};

export default RoomScreen;
