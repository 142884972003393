import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { SiteModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import Tabs from "../../../components/Tabs";
import SiteDetailsTab from "./DetailsTab";
import SiteUsersTab from "./Users";
import PlotListScreen from "../../plots";
import SiteTradesTab from "./Trades";
import SiteActionsTab from "./Actions";

const SiteShowScreen: React.FC = React.memo(() => {
  const navigate = useNavigate();
  let { siteId } = useParams();
  const [loading, setLoading] = useState(false);

  const [site, setSite] = useState<SiteModel | null>();
  const getSiteById = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<SiteModel>(`/admin/sites/${siteId}`);
      setSite(data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getSiteById();
  }, []);

  if (!site || loading)
    return (
      <div className="p-6 text-gray-500 font-medium text-lg">
        Loading site details...
      </div>
    );
  return (
    <div className="p-6">
      <div
        role="button"
        className="flex items-center text-xs font-medium text-gray-500 hover:text-black"
        onClick={() => {
          navigate('/sites');
        }}
      >
        <IoArrowBackOutline className="mr-2" />
        Back to Sites/Projects
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-orange-400 text-xl font-medium my-6">
          {site.name}
        </h1>
        {/* <div
          role="button"
          onClick={() => setShowExport(true)}
          className="bg-orange-400 text-sm my-2 rounded-md p-2 text-center text-white w-40"
        >
          Export Plot
        </div> */}
      </div>

      <Tabs
        tabs={[
          {
            id: "plots",
            title: "Plots",
            component: <PlotListScreen site={site}/>,
          },
          {
            id: "details",
            title: "Details",
            component: <SiteDetailsTab site={site} getSite={getSiteById} />,
          },
          {
            id: "users",
            title: "Users",
            component: <SiteUsersTab site={site} getSite={getSiteById} />,
          },
          {
            id: "trades",
            title: "Trades",
            component: <SiteTradesTab site={site} getSite={getSiteById} />,
          },
          {
            id: "actions",
            title: "Actions",
            component: <SiteActionsTab site={site} getSite={getSiteById} />,
          },
        ]}
      />
    </div>
  );
});

export default SiteShowScreen;
