import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  PDFDownloadLink
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import axios from "axios";
import CoverPage from "./pages/Cover";
import moment from "moment";
import FooterPage from "./pages/Footer";
import ReportPage from "./pages/Report";
// import { isMobile } from "react-device-detect";
// import ContentPage from "./pages/Content";
// import FooterPage from "./pages/Footer";
const CeraProMedium = require("./fonts/cera-pro-medium.ttf")
const CeraProRegular = require("./fonts/cera-pro-regular.ttf")


Font.register({
  family: "CeraPro",
  fonts: [
    {
      src: CeraProMedium
    }
  ]
});

Font.register({
  family: "CeraProRegular",
  fonts: [
    {
      src: CeraProRegular
    }
  ]
});

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,  // Set your base URL here
  withCredentials: true
});

interface PlotReportScreenProps {}

// Create styles
const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "100vh",
    fontFamily: "CeraPro",
  },
});

const PlotReportScreen: React.FC<PlotReportScreenProps> = () => {
  let { exportToken } = useParams();

  const [loading, setLoading] = useState(true);

  const [report, setReport] = useState<any>({});

  const getPlotReport = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/plots/exports/${exportToken}/view-report`);
      setReport(data);
    } catch (error) {
      console.log(error);
      // alert(error);
    }
    setLoading(false);
  }, [exportToken]);

  useEffect(() => {
    getPlotReport();
  }, [getPlotReport, exportToken]);

  const mobileDownload = () => {};

  if (loading)
    return (
      <div>
        Loading plot report
        <button onClick={mobileDownload}></button>
      </div>
    );


  // return <div>{JSON.stringify(report)}</div>
  
  // if (isMobile)
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <PDFDownloadLink
        document={
          <Document title={`${report.plot.site.name}_${report.plot.name}_${report.plot.stage_inspection}_${moment().format('DD-MM-YYYY')}`} creator="PlotSnag">
            <CoverPage report={report} />
            <ReportPage report={report} />
            <FooterPage />
          </Document>
        }
        fileName={`${report.plot.site.name}_${report.plot.name}_${report.plot.stage_inspection}_${moment().format('DD-MM-YYYY')}.pdf`}
        className="bg-blue-500 text-white p-4 rounded-md font-medium"
      >
        {/* @ts-ignore */}
        {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download Report"
          }
      </PDFDownloadLink>
    </div>
  );
  // return (
  //   <PDFViewer style={styles.viewer}>
  //     <Document title={`${report.plot.site.name}_${report.plot.name}_${report.plot.stage_inspection}_${moment().format('DD-MM-YYYY')}`} creator="PlotSnag">
  //       <CoverPage report={report} />
  //       <ReportPage report={report} />
  //       <FooterPage />
  //     </Document>
  //   </PDFViewer>
  // );
};

export default PlotReportScreen;
