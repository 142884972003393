import { Page, Text, View, StyleSheet, Link, Image } from "@react-pdf/renderer";


const styles = StyleSheet.create({
  coverBody: {
    fontFamily: "CeraPro",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 40,
  },
  coverSubtitle: {
    fontSize: 12,
    color: "#798286",
  },
  coverLogosLogo: {
    width: 30,
    height: 31,
    objectFit: "contain",
    marginLeft: -10
  },
  qrLogo: {
    width: 100,
    height: 100,
    objectFit: "contain",
  },
});

const FooterPage = () => {
  return (
    <Page size="A4" style={styles.coverBody}>
      <View style={{ width: '100%', height: '90vh', display: 'flex', justifyContent: 'flex-end' }}>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
              <Image src="/images/plotsnag-report.png" style={styles.coverLogosLogo} />
              <Text style={{ fontSize: 12, color: "#798286", marginBottom: 0, marginTop: 4 }} > 
                This report is powered by 
                <Link style={{ textDecoration: 'none' }} href="https://plottech.app">&nbsp;PlotTech</Link> 
              </Text>
            </View>
            <Text style={styles.coverSubtitle}>
              PlotTech Ltd is a registered company in England & Wales.
            </Text>
            <Text style={styles.coverSubtitle}>
              Registered at 86-90 Paul Street, London, England, 
            </Text>
            <Text style={styles.coverSubtitle}>
              United Kingdom, EC2A 4NE
            </Text>
            <Text style={{ ...styles.coverSubtitle, paddingTop: '10px' }}>
              Contact us at <Link style={{ textDecoration: 'none' }} href="mailto:lee@plottech.app"> lee@plottech.app </Link> or visit our <br/><Link style={{ textDecoration: 'none' }} href="https://www.plottech.app"> PlotTech Website </Link>
            </Text>
            <Text style={{ ...styles.coverSubtitle }}>for more details</Text>
          </View>
          <View style={{ width: 100, height: 100 }}>
            <Image src="/images/qr.png" style={styles.qrLogo} />
          </View>
        </View>
      </View>
    </Page>
  );
};

export default FooterPage;
