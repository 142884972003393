import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ListViewHeader from "../../components/ListViewHeader";
import { PaginatedType, PlotModel, SiteModel } from "../../types/api";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import axios from "../../plugins/axios";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
import Modal from "../../components/Modal";
import CreatePlotForm from "../../forms/CreatePlotForm";
import Table from "../../components/Table";
import Select, { MultiValue } from "react-select";

interface PlotListScreenProps {
  site: SiteModel;
}

interface PlotListFilters {
  insStageFilter: [];
  cbFilter: [];
  selectedInsStageFilter: MultiValue<never>;
  selectedCbFilter: MultiValue<never>;
  sortBy: { label: string, value: string } | null;
}

const defaultFilters: PlotListFilters = {
  insStageFilter: [],
  cbFilter: [],
  selectedInsStageFilter: [],
  selectedCbFilter: [],
  sortBy: null,
}

const sortByOptions: any = [
  {
    label: 'Inspection Stage (ASC)',
    value: 'stage_inspection',
  },
  {
    label: 'Inspection Stage (DESC)',
    value: '-stage_inspection',
  },
  {
    label: 'Created By (ASC)',
    value: 'created_by',
  },
  {
    label: 'Created By (DESC)',
    value: '-created_by',
  }
]

const PlotListScreen: React.FC<PlotListScreenProps> = React.memo(({ site }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  let { siteId } = useParams();
  const [plots, setPlots] = useState<PlotModel[]>([]);
  const [search] = useCustomSearchParams();
  const [total, setTotal] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [plotListFilter, setPlotListFilter] = useState<PlotListFilters>(defaultFilters);
  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "20"),
    };
  }, [search.page, search.per_page]);

  const getPlotsFilter = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<any>(
        `/admin/plots/${siteId}/filters`
      );
      const items = localStorage.getItem(`plotListFilter${siteId}`) || JSON.stringify(defaultFilters);
      setPlotListFilter({
        ...JSON.parse(items),
        insStageFilter: data.stages,
        cbFilter: data.cbusers,
      });
    } catch (error) {
      setError(false);
    }
    setLoading(false);
  };

  const getPlotsList = async () => {
    setLoading(true);
    try {
      const params: {
        page: number,
        per_page: number,
        search: string,
        insFilter?: string
        cbFilter?: string,
        sortBy?: string,
      } = {
        page: pagination.current_page,
        per_page: pagination.per_page,
        search: search.search
      }
      if (plotListFilter.selectedInsStageFilter && plotListFilter.selectedInsStageFilter.length > 0) {
        params["insFilter"] = plotListFilter.selectedInsStageFilter.map((obj: { value: never; }) => {
          return obj.value
        }).join(',');
      }
      if (plotListFilter.selectedCbFilter && plotListFilter.selectedCbFilter.length > 0) {
        params["cbFilter"] = plotListFilter.selectedCbFilter.map((obj: { value: never; }) => {
          return obj.value
        }).join(',');
      }
      if (plotListFilter.sortBy) {
        params["sortBy"] = plotListFilter.sortBy.value
      }
      const { data } = await axios.get<PaginatedType<PlotModel[]>>(
        `/admin/plots/${siteId}`,
        {
          params,
        }
      );
      setPlots(data.items);
      setTotal(data.total);
    } catch (error) {
      setPlots([]);
      setTotal(0);
      setError(false);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    getPlotsList()
  }, [plotListFilter, pagination, search.search])

  useEffect(() => {
    getPlotsFilter();
  }, []);

  return (
    <div>
      <ListViewHeader
        title="Plots"
        showSearch
        onCreate={() => setShowAdd(true)}
      />
      <div className="w-full px-3">
        <div className="grid grid-cols-3">
          <Select 
            className="plots-inspection-stage p-3"
            options={plotListFilter.insStageFilter}
            placeholder="Select Inspection Stage"
            onChange={(v) => {
              const data: PlotListFilters = {
                ...plotListFilter,
                selectedInsStageFilter: v
              }
              localStorage.setItem(`plotListFilter${siteId}`, JSON.stringify(data));
              setPlotListFilter(data);
            }}
            value={plotListFilter.selectedInsStageFilter}
            isMulti
            closeMenuOnSelect={false}
          />
          <Select 
            className="plots-created-by-filter p-3"
            options={plotListFilter.cbFilter}
            placeholder="Select Created By"
            onChange={(v) => {
              const data: PlotListFilters = {
                ...plotListFilter,
                selectedCbFilter: v
              }
              localStorage.setItem(`plotListFilter${siteId}`, JSON.stringify(data));
              setPlotListFilter(data);
            }}
            value={plotListFilter.selectedCbFilter}
            isMulti
            closeMenuOnSelect={false}
          />
          <Select 
            className="plots-sort-by-filter p-3"
            options={sortByOptions}
            placeholder="Sort By"
            onChange={(v) => {
              const data: PlotListFilters = {
                ...plotListFilter,
                sortBy: v
              }
              localStorage.setItem(`plotListFilter${siteId}`, JSON.stringify(data));
              setPlotListFilter(data);
            }}
            isClearable={true}
            value={plotListFilter.sortBy}
          />
        </div>
      </div>
      <Table
        total={total}
        loading={loading}
        error={error}
        rows={plots}
        pagination={pagination}
        headers={[
          "Plot Name",
          "Inspection Stage",
          "Created At",
          "Created By",
          "Is Active",
          "Open Snags",
          "",
        ]}
        messages={{
          noResults: (
            <div>
              You have not created any plots,{" "}
              <span
                role="button"
                onClick={() => setShowAdd(true)}
                className="text-orange-500"
              >
                create one
              </span>
              .
            </div>
          ),
          apiFailed:
            "Failed to load plots, please try again later or contact support",
        }}
        renderRow={(plot) => {
          return (
            <tr
              key={plot.id}
              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
            >
              <td className="py-3 pl-6">
                <Link
                  to={`/sites/${siteId}/plots/${plot.id}`}
                  className="text-orange-400 hover:text-orange-500 flex items-center justify-start"
                >
                  {plot.name}
                </Link>
              </td>
              <td className="py-2">{plot.stage_inspection}</td>
              <td className="py-2">
                {new Date(plot.created_at).toLocaleDateString()}
              </td>
              <td className="py-2">{plot.created_by}</td>
              <td className="py-2">{plot.active ? "Yes" : "No"}</td>

              <td className="py-2">{plot.snags.filter((snag: { status: string }) => snag?.status?.toLowerCase() === 'draft').length}</td>

              <td>
                <Link
                  to={`/sites/${siteId}/plots/${plot.id}`}
                  className="text-gray-400 hover:text-orange-500 cursor-pointer"
                >
                  <HiOutlineDotsHorizontal size={20} />
                </Link>
              </td>
            </tr>
          );
        }}
      />
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <CreatePlotForm
          onCompleted={async () => {
            getPlotsList();
            setShowAdd(false);
          }}
        />
      </Modal>
    </div>
  );
});

export default PlotListScreen;
