import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "../plugins/axios";
import { PlotModel } from "../types/api";
import { FormProps } from "../types/app";

type Inputs = {
  send_to: undefined | "email_addresses" | "trades" | "specificsnags";
  report_type: string;
  email?: string;
};

interface ExportPlotFormProps extends FormProps {
  plot: PlotModel;
  onCompleted: () => void;
}

const ExportPlotForm: React.FC<ExportPlotFormProps> = ({
  plot,
  onCompleted,
}) => {
  const [loading, setLoading] = useState(false);
  const [exportBy, setExportBy] = useState<"trade" | "room" | undefined>(
    undefined
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      report_type: 'live'
    }
  });

  const sendTo = watch("send_to");

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoading(true);
    try {
      await axios.get<any>(`/plots/${plot.id}/export`, {
        params: {
          sortBy: exportBy,
          sendTo: formData.send_to === 'email_addresses' ? 'email_addresses' : 'trades',
          reportType: formData.report_type,
          sendToSpecific: formData.send_to === 'specificsnags',
          email: formData.email,
        },
      });
      onCompleted();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Export Report</h2>

      {exportBy !== undefined ? (
        <>
          <div className="my-6">
            <label htmlFor="send_to" className="block mb-2 font-medium text-sm">
              Send Export to:
            </label>
            <select
              {...register("send_to")}
              placeholder="-- How would you like to send the export --"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            >
              <option
                value={undefined}
                disabled
                selected
                className="text-gray-400"
              >
                -- Choose option --
              </option>

              <option value="email_addresses">Single Email Address</option>
              <option value="trades">Assigned Trades - All Snags</option>
              <option value="specificsnags">Assigned Trades - Assigned Snags ONLY</option>
            </select>
          </div>

          <div className="my-6">
            <label htmlFor="report_type" className="block mb-2 font-medium text-sm">
              Report Type:
            </label>
            <select
              {...register("report_type")}
              placeholder="-- How would you like to send the export --"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            >
              <option
                value={undefined}
                disabled
                selected
                className="text-gray-400"
              >
                -- Choose option --
              </option>

              <option value="live">Open Report - OPEN Snags only</option>
              <option value="final">Full Report - ALL Snags</option>
            </select>
          </div>

          {sendTo === "email_addresses" && (
            <div className="my-6">
              <label htmlFor="name" className="text-sm font-medium mb-2">
                Enter email
              </label>
              <input
                {...register("email", { required: true, minLength: 3 })}
                placeholder="Enter email address"
                type="email"
                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              />
              {errors.email && (
                <span className="mt-2 text-xs text-red-600 font-medium">
                  Email address is required is required and must be more than 3
                  characters
                </span>
              )}
            </div>
          )}
          <button
            type="submit"
            className="focus:outline-none text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-orange-900"
          >
            {loading ? "Loading..." : "Export plot"}
          </button>
        </>
      ) : (
        <div className="w-full flex items-center justify-center flex-col">
          <h3 className="text-gray-600 mb-6">Order export:</h3>
          <button
            type="button"
            onClick={() => setExportBy("trade")}
            className="focus:outline-none text-gray-600 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-10 py-2.5 mr-2 dark:focus:ring-orange-900 mb-4"
          >
            By Trade
          </button>
          <button
            type="button"
            onClick={() => setExportBy("room")}
            className="focus:outline-none text-gray-600 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-10 py-2.5 mr-2 mb-2 dark:focus:ring-orange-900"
          >
            By Room
          </button>
        </div>
      )}
    </form>
  );
};

export default ExportPlotForm;
