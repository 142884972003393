import React, { useCallback, useEffect, useState } from "react";
import { PlotModel, SnagModel, SnagModelV2 } from "../../../types/api";
import axios from "../../../plugins/axios";
import ApiImage from "../../../components/ApiImage";
import Modal from "../../../components/Modal";
import { IoCloseOutline } from "react-icons/io5";
interface PlotSnagsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
}

const PlotSnagsTab: React.FC<PlotSnagsTabProps> = ({ plot, getPlot }) => {
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState('');
  const [snags, setSnags] = useState<SnagModelV2[]>([]);

  const getSnags = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/plots/v2/${plot.id}/snags/full`);
      setSnags(data);
    } catch (error) {}
    setLoading(false);
  }, [plot.id]);

  useEffect(() => {
    getSnags();
  }, [getSnags, plot.id]);


  return (
    <div style={{ overflowX: 'scroll' }}>
      {loading && <div className="my-2">Loading...</div>}

      <table style={{ overflowX: 'scroll' }} className="table-auto w-full text-left bg-white rounded-t-md">
        <thead className="mb-2 h-12 border-b border-gray-100">
          <tr className="text-gray-400 font-light">
            <th className="p-2 pl-6">Type</th>
            <th>Details</th>

            <th>Snag Type</th>
            <th>Room</th>
            <th>Trade Name</th>
            <th>Images</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Created By</th>
            <th>Updated At</th>
            <th>Updated By</th>
          </tr>
        </thead>
        <tbody>
          {snags.map((snag) => (
            <tr
              key={snag.id}
              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
            >
              <td className="py-3 px-2 pl-6">{snag.issue_type}</td>
              <td style={{ minWidth: '250px', maxWidth: '250px', wordBreak: 'break-all'}} className="py-2 px-2">{snag.details}</td>

              <td className="py-2 px-2">{snag.snag_location_title}</td>
              <td className="py-2 px-2">{snag.room_title}</td>

              <td style={{ minWidth: '250px', maxWidth: '250px', wordBreak: 'break-all'}} className="py-2 px-2">
                {snag.trade}
              </td>

              <td style={{ minWidth: '200px'}} className="py-2 flex">
                {snag.file_1 && snag.file_1 !== "" && (
                  <ApiImage
                    setDialogOpen={setDialogOpen}
                    imageUrl={snag.file_1}
                    imageProps={{
                      alt: "Snag image 1",
                      className: "w-10 h-10 contain rounded-md mr-4 cursor-pointer",
                    }}
                  />
                )}
                {snag.file_2 && snag.file_2 !== "" && (
                  <ApiImage
                    setDialogOpen={setDialogOpen}
                    imageUrl={snag.file_2}
                    imageProps={{
                      alt: "Snag image 2",
                      className: "w-10 h-10 contain rounded-md mr-4 cursor-pointer",
                    }}
                  />
                )}
                {snag.file_3 && snag.file_3 !== "" && (
                  <ApiImage
                    setDialogOpen={setDialogOpen}
                    imageUrl={snag.file_3}
                    imageProps={{
                      alt: "Snag image 3",
                      className: "w-10 h-10 contain rounded-md mr-4 cursor-pointer",
                    }}
                  />
                )}
              </td>
              <td className="py-2 px-2">
                {snag.status === 'draft' ? 'Open' : 'Resolved'}
              </td>
              <td className="py-2 px-2">
                {new Date(snag.created_at).toLocaleDateString()}
              </td>
              <td className="py-2 px-2">
                {snag.created_by}
              </td>
              <td className="py-2 px-2">
                {new Date(snag.updated_at).toLocaleDateString()}
              </td>
              <td className="py-2 px-2">
                {snag.updated_by}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {
        dialogOpen && (
          <div className="fixed top-0 left-0 bg-black bg-opacity-50 w-screen h-screen flex items-center justify-center overscroll-none p-4 z-50">
            <dialog
              className="dialog"
              open
              onClick={() => setDialogOpen('')}
            >
              <button
                className="absolute text-white -top-10 -right-2 rounded-full cursor-pointer hover:text-red-600 z-50"
                onClick={() => setDialogOpen('')}
                type="button"
              >
                <IoCloseOutline size={35} />
              </button>
              <img
                className="image"
                src={dialogOpen}
                onClick={() => setDialogOpen('')}
                alt="no image"
              />
            </dialog>  
          </div>
        )
      }
    </div>
  );
};

export default PlotSnagsTab;
