import React from "react";
import { useNavigate } from "react-router-dom";

interface UnregisteredUserScreenProps {}

const UnregisteredUserScreen: React.FC<UnregisteredUserScreenProps> = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen fixed left-0 top-0 bg-white flex items-center justify-center flex-col">
      <img src="/images/lost.png" alt="User lost" />
      <h1 className="text-5xl font-medium text-orange-400 my-6">
        Account Not Found
      </h1>
      <h3 className="text-xl text-gray-500 max-w-lg text-center">
        It seems that you have an account but are not a part of a company, this
        can usually be solved by opening the PlotSnag mobile app and logging in.
      </h3>
      <button
        onClick={() => navigate("/")}
        className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm mt-4"
      >
        I've logged into the app
      </button>
    </div>
  );
};

export default UnregisteredUserScreen;
