import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PlotModel } from "../../../types/api";

interface PlotDetailsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
}

const PlotActionsTab: React.FC<PlotDetailsTabProps> = ({ plot, getPlot }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const toggleArchivedStatus = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await axios.post(`plots/${plot.id}/archive-unarchive`);
      getPlot();
      toast("Plot status updated", { type: "success" });
    } catch (error) {
      toast("Failed to update plot status", { type: "error" });
    }
    setLoading(false);
  };

  const deletePlot = async () => {
    if (
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to permanently delete this plot, this cannot be undone!"
      )
    ) {
      try {
        await axios.delete(`plots/${plot.id}`);
        toast("Plot has been deleted successfully", {
          type: "success",
        });

        navigate("/plots");
      } catch (error) {
        toast("Failed to delete plot, please contact support", {
          type: "error",
        });
      }
    }
  };
  return (
    <div className="max-w-2xl">
      <div className="p-4 rounded-md bg-white">
        <h3 className="text-xl font-medium">Plot status</h3>
        <>
          <p className="text-gray-500 text-sm">
            Control the status of this plot archived / active
          </p>
          {plot.active ? (
            <div
              role="button"
              onClick={() => {
                toggleArchivedStatus();
              }}
              className="bg-orange-300 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
            >
              {loading ? "Loading...." : "Archive"}
            </div>
          ) : (
            <div
              role="button"
              onClick={() => {
                toggleArchivedStatus();
              }}
              className="bg-green-600 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
            >
              {loading ? "Loading...." : "Mark as active"}
            </div>
          )}
        </>
      </div>
      <div className="p-4 rounded-md bg-white mt-6">
        <h3 className="text-xl font-medium">Delete Plot</h3>
        <>
          <p className="text-gray-500 text-sm">
            Remove this plot and all the data within{" "}
            <strong>This cannot be undone!</strong>
          </p>
          <div
            role="button"
            onClick={() => {
              deletePlot();
            }}
            className="bg-red-600 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
          >
            {loading ? "Loading...." : "Delete"}
          </div>
        </>
      </div>
    </div>
  );
};

export default PlotActionsTab;
