import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

interface CoverStatusItemProps {
  title: string;
  content: string;
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 20
  },
  title: {
    fontFamily: "CeraProRegular",
    fontWeight: 900,
    color: "#798286",
    fontSize: 11,
  },
  content: {
    color: "#45505E",
    fontSize: 14,
  },
});
const CoverStatusItem: React.FC<CoverStatusItemProps> = ({
  title,
  content,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.content}>{content}</Text>
    </View>
  );
};

export default CoverStatusItem;
