import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PlotModel, SiteModel } from "../../../types/api";

interface PlotDetailsTabProps {
  site: SiteModel;
  getSite: () => Promise<void>;
}

const SiteActionsTab: React.FC<PlotDetailsTabProps> = ({ site, getSite }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const deleteSite = async () => {
    if (
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to permanently delete this site, this cannot be undone!"
      )
    ) {
      try {
        await axios.delete(`sites/${site.id}`);
        toast("Site has been deleted successfully", {
          type: "success",
        });

        navigate("/sites");
      } catch (error) {
        toast("Failed to delete plot, please contact support", {
          type: "error",
        });
      }
    }
  };
  return (
    <div className="max-w-2xl">
      <div className="p-4 rounded-md bg-white mt-6">
        <h3 className="text-xl font-medium">Delete Site</h3>
        <>
          <p className="text-gray-500 text-sm">
            Remove this site and all the data within{" "}
            <strong>This cannot be undone!</strong>
          </p>
          <div
            role="button"
            onClick={() => {
              deleteSite();
            }}
            className="bg-red-600 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
          >
            {loading ? "Loading...." : "Delete"}
          </div>
        </>
      </div>
    </div>
  );
};

export default SiteActionsTab;
