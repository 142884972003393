import React, { useCallback, useEffect, useState } from "react";
import { SiteModel, TradeModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import CognitoUser from "../../../components/CognitoUser";
import { toast } from "react-toastify";

interface SiteDetailsTabProps {
  site: SiteModel;
  getSite: () => Promise<void>;
}

const SiteTradesTab: React.FC<SiteDetailsTabProps> = ({ site, getSite }) => {
  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState<TradeModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(true);

  const [addedTrades, setAddedTrades] = useState(site.trades);

  const getAllTrades = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const { data } = await axios.get<TradeModel[]>(`/admin/trades/list/sites`);
      console.log('trades data- --->', data);
      setTrades(data);
    } catch (error) {
      setTrades([]);
    }
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    getAllTrades();
  }, [getAllTrades]);

  const addTradeToSite = async (tradesList: Array<number>, trade: TradeModel, isAdd: boolean) => {
    setLoading(true);
    try {
      await axios.put(`/admin/sites/${site.id}`, {
        ...site,
        trades: JSON.stringify(tradesList),
        users: JSON.stringify(site.users),
      });
      toast(`${trade.name} is ${isAdd ? 'added to the site' : 'removed from the site'}`, { type: "success" });
      getSite();
    } catch (error) {
      toast("Failed to update site trades", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div className="w-full">
      <div className="p-5 mb-5 bg-orange-200 bg-opacity-50 italic text-gray-600 border-2 border-opacity-50 border-orange-500 rounded-md">
      Here you can manage which trades should be made available on the <b>{site.name}</b> site by toggling them on and off. 
      Removing trades can help reduce the total list that users will see in the app. 
      New trades can be added in the <a href="/trades" className="text-blue-600">directory page</a>.
      </div>
      <div className="w-full rounded-md overflow-hidden">
        {trades.length > 0 ? (
          <>
            <div className="relative">
            <div className="grid grid-cols-2 gap-4 mb-8">
              <div>
                <div
                  className="bg-white w-full h-10 flex items-center justify-between rounded-md overflow-hidden"
                >
                  <input
                    placeholder="Enter search term (3 characters or more)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full h-10 p-1 pl-6 focus:outline-none"
                  />
                </div>
              </div>
            </div>
              <div className="relative" style={{ minHeight: 300 }}>
                <table className="table-auto w-full text-left bg-white rounded-t-md">
                  <thead className="mb-2 h-12 border-b border-gray-100">
                    <tr className="text-gray-400 font-light">
                      {["Name", "Company Name", "Email", "Type", "Availability"].map((header, index) => {
                        if (index === 0) return <th key={header} className="p-2 pl-6">{header}</th>;
                        return <th key={header}>{header}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      trades.map((trade) => {
                        if ((
                          searchTerm && (
                            trade.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            trade.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            trade.email.toLowerCase().includes(searchTerm.toLowerCase())
                          )
                        ) || searchTerm == '') {
                          return (
                            <tr
                              key={trade.id}
                              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
                            >
                              <td className="py-3 pl-6">
                                <p
                                  className="text-orange-400 hover:text-orange-500 flex items-center justify-start"
                                >
                                  {trade.name}
                                </p>
                              </td>
                              <td className="py-2">{trade.company_name}</td>
                              <td className="py-2">{trade.email}</td>

                              <td className="py-2">{trade.trade.title}</td>
                              <td>
                                <div 
                                  onClick={() => {
                                    console.log('hereewfrtefwrfwe')
                                    let tempTrades = addedTrades;
                                    let isAdd = true;
                                    if (addedTrades.includes(trade.id)) {
                                      tempTrades = addedTrades.filter((aT) => aT !== trade.id)
                                      setAddedTrades(tempTrades);
                                      isAdd = false;
                                    } else {
                                      tempTrades = [...tempTrades, trade.id];
                                      setAddedTrades(tempTrades);
                                    }
                                    addTradeToSite(tempTrades, trade, isAdd);
                                  }}
                                  className="round-checkbox"
                                >
                                  <input
                                    id="manage-trades"
                                    type="checkbox"
                                    placeholder="Admin"
                                    checked={addedTrades.includes(trade.id)}
                                    className="w-4 h-4 cursor-pointer text-orange-400 bg-white rounded border-white focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-white dark:border-white mr-4"
                                  />
                                  <label htmlFor="checkbox"></label>
                                </div>
                              </td>
                            </tr>
                          )
                        }    
                      })
                        
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <>
            {loadingUsers ? (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                Loading Trades List
              </div>
            ) : (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                You do not currently have any active trades, Add a trade.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SiteTradesTab;
