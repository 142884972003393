import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { PlotModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import Tabs from "../../../components/Tabs";
import PlotDetailsTab from "./DetailsTab";
import PlotSnagsTab from "./Snags";
import PlotActionsTab from "./Actions";
import Modal from "../../../components/Modal";
import ExportPlotForm from "../../../forms/ExportPlotForm";
import PlotUsersTab from "./Users";

const PlotShowScreen: React.FC = () => {
  const navigate = useNavigate();
  let { plotId, siteId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showExport, setShowExport] = useState(false);

  const [plot, setPlot] = useState<PlotModel | null>();
  const getPlotById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PlotModel>(`/admin/plots/plot-detail/${plotId}`);
      setPlot(data);
    } catch (error) {}
    setLoading(false);
  }, [plotId]);

  useEffect(() => {
    getPlotById();
  }, [getPlotById]);

  if (!plot || loading)
    return (
      <div className="p-6 text-gray-500 font-medium text-lg">
        Loading plot details...
      </div>
    );
  return (
    <div className="p-6">
      <div
        role="button"
        className="flex items-center text-xs font-medium text-gray-500 hover:text-black"
        onClick={() => {
          navigate(`/sites/${siteId}`);
        }}
      >
        <IoArrowBackOutline className="mr-2" />
        Back to site
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-orange-400 text-xl font-medium my-6">
          {plot.name}
        </h1>
        <div
          role="button"
          onClick={() => setShowExport(true)}
          className="bg-orange-400 text-sm my-2 rounded-md p-2 text-center text-white w-40"
        >
          Export Report
        </div>
      </div>

      <Tabs
        tabs={[
          {
            id: "snags",
            title: "Snags",
            component: <PlotSnagsTab plot={plot} getPlot={getPlotById} />,
          },
          {
            id: "users",
            title: "Users",
            component: <PlotUsersTab plot={plot} getPlot={getPlotById} />,
          },
          {
            id: "details",
            title: "Details",
            component: <PlotDetailsTab plot={plot} getPlot={getPlotById} />,
          },
          {
            id: "actions",
            title: "Actions",
            component: <PlotActionsTab plot={plot} getPlot={getPlotById} />,
          },
        ]}
      />
      <Modal shown={showExport} onClose={() => setShowExport(false)}>
        <ExportPlotForm plot={plot} onCompleted={() => setShowExport(false)} />
      </Modal>
    </div>
  );
};

export default PlotShowScreen;
